<template>
  <div class="d-md-flex mt-2">
    <p class="mb-0 mt-2">
      <span v-if="prices != null">
        <div style="border-left: 3px solid #6E30E8; padding-left: 8px">
          <div
            v-if="getPvpInfo(prices).value >= getPvpTransferInfo(prices).value"
          >
            <price-display
              v-if="this.show_full_prices"
              :price="getPvpTransferInfo(prices)"
              :isAuth="isAuth"
            />
            <br v-if="this.show_full_prices" />
            <price-display :price="getPvpInfo(prices)" :isAuth="isAuth" />
            <br v-if="this.show_full_prices" />
            <price-display :price="getQoutesInfo(prices)" :isAuth="isAuth" />
          </div>
          <div v-else>
            <price-display :price="getPvpInfo(prices)" :isAuth="isAuth" />
            <br />
            <price-display
              :price="getPvpTransferInfo(prices)"
              :isAuth="isAuth"
            />
          </div>
          <!-- <div class="mt-5 text-17">
            <span class="font-weight-bold text-20">
              $
              {{ prices.pvp_18_installments | currencyPVP }}
            </span>
            hasta 18 cuotas de $
            {{ (prices.pvp_18_installments / 18) | currencyPVP }}
          </div> -->
        </div>
        <div class="mt-4">
          <i>Podras seleccionar la forma de pago en el Checkout</i>
        </div>
      </span>
    </p>
  </div>
</template>

<script>
import ProductDetailsSinglePrice from "./ProductDetailsSinglePrice.vue";

export default {
  props: {
    prices: {
      type: Object,
      required: true,
      default: () => {}
    },
    show_full_prices: {
      type: Boolean,
      required: false,
      default: false
    },
    isAuth: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  components: {
    "price-display": ProductDetailsSinglePrice
  },

  methods: {
    getPvpInfo(prices) {
      return {
        paymentType: "con Débito / Crédito 1 Cuota",
        value: prices.pvp,
        discount: Math.round(prices.discount),
        value_no_discount: prices.pvp_no_discount
      };
    },

    getPvpTransferInfo(prices) {
      return {
        paymentType: "por Transferencia Bancaria",
        value: prices.pvp_transfer,
        discount: Math.round(prices.transfer_discount),
        value_no_discount: prices.pvp_transfer_no_discount
      };
    },
    getQoutesInfo(prices) {
      console.log(prices);
      return {
        paymentType: `hasta 12 cuotas de $ ${new Intl.NumberFormat("de-DE", {
          // currency: "ARS",
          // style: "currency",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        }).format(prices.pvp_18_installments / 12)}`,
        value: prices.pvp_18_installments,
        discount: 0,
        value_no_discount: prices.pvp_18_installments
      };
    }
  },

  filters: {
    currencyPVP(value) {
      if (value) {
        const AMOUNT_FORMAT = new Intl.NumberFormat("de-DE", {
          // currency: "ARS",
          // style: "currency",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        }).format(value);
        return AMOUNT_FORMAT;
      } else {
        return " ";
      }
    }
  },

  data() {
    return {};
  }
};
</script>

<style scoped>
.text-20 {
  font-size: 1.5em;
}

.text-17 {
  font-size: 17px !important;
}
</style>
