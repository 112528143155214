<template>
  <div>
    <v-card v-if="showVerification" width="400" flat>
      <v-alert outlined type="warning" prominent border="left">
        {{ messageAlert }}
      </v-alert>
    </v-card>
    <v-card
      width="400"
      flat
      class="px-0 py-0 animate__animated animate__backInDown"
      style="
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
        border-radius: 20px !important;
      "
    >
      <div class="text-center login-title-card">Bienvenido</div>
      <div class="text-center mt-5 login-sub-title-card">
        Ingresa con tu cuenta
      </div>

      <ValidationObserver ref="obs" v-slot="{ passes }">
        <v-col cols="12" md="12">
          <label for="email">Correo Electrónico</label>
          <ValidationProvider
            name="Nombre"
            rules="email|required"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="email"
              class="mt-2"
              color="#6E30E8"
              dense
              filled
              placeholder="ejemplo@mail.com"
              :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="12" class="mt-n5">
          <label for="email">Contraseña</label>
          <ValidationProvider
            name="Contraseña"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              type="password"
              v-model="password"
              class="mt-2"
              color="#6E30E8"
              dense
              filled
              placeholder="Ingresa tu contraseña"
              :error-messages="errors"
              @keyup.enter="passes(HandlerLogin)"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="12">
          <v-btn
            rounded
            elevation="0"
            large
            block
            dark
            color="#6E30E8"
            class="text-capitalize"
            :loading="loading"
            @click="passes(HandlerLogin)"
          >
            Ingresar
          </v-btn>
          <v-btn
            rounded
            elevation="0"
            large
            block
            color="black"
            class="text-capitalize mt-1 white--text"
            :loading="loading"
            @click="emitEvent"
          >
            Registrar
          </v-btn>
        </v-col>
      </ValidationObserver>

      <v-col class="text-center mt-n7 mt-md-n5" cols="12" sm="12" md="12">
        <div @click="emitRecovery" class="mt-6 recovery-text">
          Recuperar contraseña
        </div>
      </v-col>
    </v-card>

    <verification-component
      :showVerification="verifyAccount"
      :statusRegister="false"
      :emailUser="email"
      @verification:change="VerificationUser"
    />
  </div>
</template>

<script>
import VerificationAccountComponet from "../AuthUtils/Dialogs/VerificationAccountComponet.vue";
export default {
  components: {
    "verification-component": VerificationAccountComponet
  },

  props: {
    emailUser: {
      type: String,
      default: "",
      required: false
    },
    passwordUser: {
      type: String,
      default: "",
      required: false
    }
  },

  data() {
    return {
      //Login
      loading: false,
      email: "",
      password: "",
      //Validation
      showVerification: false,
      verifyAccount: false,
      messageAlert: ""
    };
  },

  created() {
    this.email = this.emailUser;
    this.password = this.passwordUser;
  },

  watch: {
    showVerification(val) {
      if (val == true) {
        setTimeout(() => (this.showVerification = false), 4000);
      }
    }
  },

  computed: {
    prevRoutePath() {
      return this.prevRoute.path ?? "/";
    }
  },

  methods: {
    async HandlerLogin() {
      try {
        this.statusRegister = false;
        this.loading = true;
        const request = {
          email: this.email,
          password: this.password,
          store: process.env.VUE_APP_STORE_ID
        };
        await this.$store.dispatch("auth/LOGIN", request);
        this.$snotify.success("Nos encanta tenerte de vuelta", "Bienvenido");
        const prevPage = this.$store.getters["auth/GET_PREV_PAGE"];
        if (prevPage !== null) {
          this.$router.back();
        } else {
          this.$router.push("/");
        }
      } catch (error) {
        console.log(error);
        if (error?.response?.status == 401) {
          this.$snotify.error("Usuario no encontrado", "Error!");
        } else if (error.response.status == 622) {
          this.verifyAccount = true;
          this.messageAlert = "Para ingresar debe validar su cuenta";
        } else {
          this.$snotify.error(error.response.data.error.err_message, "Error!");
        }
      } finally {
        this.loading = false;
      }
    },

    emitEvent() {
      this.$emit("login:change");
    },

    emitRecovery() {
      this.$emit("recovery:change");
    },

    VerificationUser(val) {
      this.verifyAccount = false;
      if (val == false) return;
      this.HandlerLogin();
    }
  }
};
</script>

<style></style>
