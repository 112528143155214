<template>
  <v-responsive>
    <ValidationObserver ref="obs" v-slot="{ passes }">
      <v-row justify="center" style="background-color: #dad8cc">
        <v-col cols="12" md="5" class="my-10">
          <div
            class="text-center"
            style="font-color: #262626; font-size: 1.6em; font-weight: 400"
          >
            SÉ PARTE DE NUESTRA COMUNIDAD
          </div>
          <p class="text-center">
            Suscribite a nuestro Newsletter y mantenete informado sobre nuevos
            lanzamientos, ofertas, promociones y mucho mas
          </p>
          <div class="d-flex justify-center">
            <div
              class="px-5 d-flex"
              :style="
                $vuetify.breakpoint.mdAndUp ? 'width: 70%' : 'width: 100%'
              "
            >
              <v-text-field
                flat
                solo
                dense
                outlined
                single-line
                color="#6E30E8"
                label="EMAIL"
                v-model="email"
                :full-width="$vuetify.breakpoint.smAndDown ? true : false"
                style="
                  width: 230px;
                  border-color: #c9cdd9;
                  border-radius: 0px;
                  border-top-left-radius: 20px;
                  border-bottom-left-radius: 20px;
                "
              ></v-text-field>
              <v-btn
                class="elevation-0"
                style="
                  border-radius: 0px;
                  border-top-right-radius: 20px;
                  border-bottom-right-radius: 20px;
                "
                @click="passes(HandlerSuscribe)"
                color="#6E30E8"
                height="40px"
                :width="$vuetify.breakpoint.smAndUp ? '200px' : 'auto'"
                :loading="loading"
                dark
              >
                <span style="font-size: 1.2em">SUSCRIBITE</span>
              </v-btn>
            </div>
          </div>
          <div class="text-center red--text" v-if="showError">
            El correo debe ser valido
          </div>
        </v-col>
      </v-row>
    </ValidationObserver>
  </v-responsive>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      email: "",
      showError: false
    };
  },

  methods: {
    async HandlerSuscribe() {
      try {
        this.showError = false;
        if (
          /^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$/.test(
            this.email
          ) ||
          /@/.test(this.email)
        ) {
          this.loading = true;
          const request = {
            email: this.email,
            store_id: 1
          };
          await this.$store.dispatch("products/SUSCRIBE_EMAIL", request);
          this.email = "";
          this.$refs.obs.reset();
          this.$snotify.success("Solicitud enviada", "Exitos!");
        } else {
          this.showError = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
