import Vue from 'vue'
import UnderMaintenance from "./UnderMaintenance.vue";
import store from './store'
import router from './router'
import './plugins/font-awesome'
import vuetify from './plugins/vuetify'
import './plugins/vee-validate'
import './plugins/snotify'
import './plugins/crypto'
import './plugins/cliport'

import VueAnimated from '@codekraft-studio/vue-animated'

Vue.use(VueAnimated)

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import vuescroll from "vuescroll/dist/vuescroll-native";
// import the css file
import "vuescroll/dist/vuescroll.css";

Vue.use(vuescroll);

// Font awesome end
// filtros
import filters from "./filters";
Vue.use(filters);

Vue.config.productionTip = false

store.dispatch('auth/TRY_AUTO_LOGIN');

new Vue({
  store,
  router,
  vuetify,
  render: h => h(UnderMaintenance)
}).$mount('#app')
