import { product } from '../../services'

const state = {}

const getters = {}

const mutations = {}

const actions = {
    async GET_BRANDS(_, payload) {
        try {
            const response = await product.get(
                `/api/brand/brand?has_publications=true&store_id=${payload.store_id}`,
                payload
            );
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },
}

export const brands = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
