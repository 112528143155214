var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"animate__animated animate__backInUp",staticStyle:{"box-shadow":"0 10px 15px -3px rgba(0, 0, 0, 0.1) !important","border-radius":"20px !important"},attrs:{"width":"600","flat":""}},[_c('div',{staticClass:"text-center login-title-card"},[_vm._v("Bienvenido")]),_c('div',{staticClass:"text-center mt-5 login-sub-title-card"},[_vm._v("Registra tu cuenta")]),_c('ValidationObserver',{ref:"obsReg",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-row',[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"ml-3",attrs:{"for":"nombre"}},[_vm._v("Nombre")]),_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 pl-3 pr-3 px-sm-3 pr-md-0",attrs:{"color":"black","dense":"","filled":"","placeholder":"Ingresa tu nombre","error-messages":errors},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"ml-3 ml-sm-3 ml-md-0",attrs:{"for":"Codigo Postal"}},[_vm._v(" Codigo Postal ")]),_c('ValidationProvider',{attrs:{"name":"codigo postal","rules":"required|min:4|max:4|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 ml-3 ml-sm-3 ml-md-0 pr-3",attrs:{"color":"black","dense":"","filled":"","placeholder":"Ingresa tu codigo postal","error-messages":errors},model:{value:(_vm.postal_code),callback:function ($$v) {_vm.postal_code=$$v},expression:"postal_code"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"correo","rules":"email|required|confirmed:email_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ml-3",attrs:{"for":"email"}},[_vm._v(" Correo Electrónico ")]),_c('v-text-field',{staticClass:"mt-2 pl-3 pr-3 pr-sm-3 pr-md-0",attrs:{"autocomplete":"off","color":"black","dense":"","filled":"","placeholder":"Ingresa tu correo electronico","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"confirmar correo","rules":"email|required","vid":"email_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ml-3 ml-sm-3 ml-md-0",attrs:{"for":"email confirmar"}},[_vm._v(" Confirmar Correo ")]),_c('v-text-field',{staticClass:"mt-2 pr-3 ml-3 ml-sm-3 ml-md-0",attrs:{"autocomplete":"off","color":"black","dense":"","filled":"","placeholder":"Confrima tu correo electronico","error-messages":errors},model:{value:(_vm.email_confirmation),callback:function ($$v) {_vm.email_confirmation=$$v},expression:"email_confirmation"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"contraseña","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ml-3",attrs:{"for":"password"}},[_vm._v(" Contraseña ")]),_c('v-text-field',{staticClass:"mt-2 pl-3 pr-3 pr-sm-3 pr-md-0",attrs:{"autocomplete":"off","type":"password","color":"black","dense":"","filled":"","placeholder":"Ingresa tu contraseña","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"confirmar contraseña","rules":"required","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"ml-3",attrs:{"for":"password confirmar"}},[_vm._v(" Confirmar Contraseña ")]),_c('v-text-field',{staticClass:"mt-2 pr-3 ml-3 ml-sm-3 ml-md-0",attrs:{"autocomplete":"off","type":"password","color":"black","dense":"","filled":"","placeholder":"Confirma tu nombre contraseña","error-messages":errors},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"mx-3"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"block":"","loading":_vm.loading,"large":"","elevation":"0","dark":"","color":"#6E30E8","rounded":""},on:{"click":function($event){return passes(_vm.HandlerSignUp)}}},[_vm._v(" Registrar ")])],1)]),_c('v-col',{staticClass:"text-center mt-n7 mt-md-n5",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"mt-6 mb-4 recovery-text",on:{"click":_vm.emitEvent}},[_vm._v(" Ya tienes cuenta? Inicia sesion ")])])],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"450"},model:{value:(_vm.showMessage),callback:function ($$v) {_vm.showMessage=$$v},expression:"showMessage"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-5 pb-0"},[_c('v-alert',{attrs:{"outlined":"","prominent":"","border":"left","type":"success"}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Tu registro fue exitoso")]),_c('div',[_vm._v(_vm._s(_vm.email))]),_c('div',{staticClass:"font-weight-bold"},[_vm._v("¡Bienvenido!")])])])],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.emitEvent()}}},[_vm._v("Ok")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }