<template>
  <v-sheet style="position:relative">
    <v-img
      v-if="imageBanner() && productsCategories.length"
      contain
      :src="productsCategories[0].image_url"
      :lazy-src="productsCategories[0].image_url"
      style="min-height:100px"
    >
      <v-container fill-height>
        <v-row justify="center" no-gutters>
          <v-col cols="12" class="d-flex justify-center">
            <div class="font-weight-bold d-flex">
              <span style="color: #6E30E8; font-size: 2.5em">
                {{ productsCategories[0].name }}
              </span>
            </div>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <div style="color: white" class="d-flex">
              Descubre tu mejor opción
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
    <v-img
      v-else
      contain
      src="@/assets/img/banners/todos_180.webp"
      lazy-src="@/assets/img/banners/todos_180.webp"
    >
    </v-img>
    <v-col v-if="!isAuth" cols="12" md="9" class="banner-box">
      <div class="banner-container">
        <div class="banner-text">
          Regístrate para acceder a beneficios y promociones
        </div>
        <div class="banner-btn">
          <v-btn
            block
            color="black"
            class="white--text"
            rounded
            style="height: 28px; text-transform: none;"
            @click="HandlerRouter('login', { propsShow: false })"
          >
            Regístrate aquí
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-sheet>
</template>

<script>
export default {
  props: {
    productsCategories: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    HandlerRouter(router, params) {
      this.$router
        .push({
          name: router,
          params: params
        })
        .catch(err => {});
    },
    imageBanner() {
      const routeName = this.$route.query;
      if (!routeName.data && !routeName.sub_cat && !routeName.brand) {
        return false;
      }
      return true;
    }
  },
  computed: {
    isAuth() {
      return this.$store.getters["auth/AUTHENTICATED"];
    }
  }
};
</script>

<style>
.banner-box {
  position: absolute;
  padding: 0;
  bottom: -22px;
  right: 10px;
  padding: 0px 30px !important;
}
.banner-container {
  background-color: #6e30e8;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  position: relative;
  height: 38px;
}
.banner-text {
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin-right: 8px;
}
.banner-btn {
  position: absolute;
  right: 12px;
}
@media only screen and (max-width: 800px) {
  .banner-container {
    justify-content: start;
  }
  .banner-text {
    font-size: 14px;
    margin-right: 0px;
  }
  .banner-container {
    height: 34px;
  }
  .banner-box {
    right: 0px;
    padding: 0px 10px !important;
  }
}
@media only screen and (max-width: 480px) {
  .banner-container {
    height: auto;
    flex-direction: column;
  }
  .banner-btn {
    position: relative;
    margin-top: 2px;
  }
}
</style>
