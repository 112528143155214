var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card',{staticClass:"elevation-0 py-5 d-flex justify-center",attrs:{"color":"white","tile":""}},[_c('v-card-text',[_c('v-row',{staticClass:"mx-5 my-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',{staticClass:"text-uppercase font-weight-bold",attrs:{"for":"password_current"}},[_vm._v(" Contraseña actual ")]),_c('ValidationProvider',{attrs:{"name":"contraseña actual","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 elevation-0",attrs:{"type":"password","color":"#6E30E8","dense":"","filled":"","flat":"","error-messages":errors},model:{value:(_vm.password_old),callback:function ($$v) {_vm.password_old=$$v},expression:"password_old"}})]}}],null,true)}),_c('label',{staticClass:"text-uppercase font-weight-bold",attrs:{"for":"new_password"}},[_vm._v(" Nueva contraseña ")]),_c('ValidationProvider',{attrs:{"name":"contraseña nueva","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 elevation-0",attrs:{"type":"password","color":"#6E30E8","dense":"","filled":"","flat":"","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('label',{staticClass:"text-uppercase font-weight-bold",attrs:{"for":"email"}},[_vm._v(" Confirmar contraseña ")]),_c('ValidationProvider',{attrs:{"name":"confirmar contraseña","rules":"required","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 elevation-0",attrs:{"type":"password","color":"#6E30E8","dense":"","filled":"","flat":"","error-messages":errors},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center mt-5"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"loading":_vm.loading,"elevation":"0","rounded":"","dark":"","color":"#6E30E8","width":"250"},on:{"click":function($event){return passes(_vm.HandlerChangePassword)}}},[_vm._v(" Actualizar ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }