<template>
  <div v-if="price != null">
    <div v-if="price.discount === 0" class="text-17">
      <span class="font-weight-bold text-20">
        $ {{ price.value_no_discount | currencyPVP }}
      </span>
      <span>{{ price.paymentType }}</span>
    </div>
    <div v-else class="text-17">
      <v-row>
        <v-col align-self="end" class="pb-0">
          <span
            class="weight-medium text-12 text-decoration-line-through text--secondary"
          >
            $ {{ price.value_no_discount | currencyPVP }}
          </span>
          <span class="ml-3">{{ price.paymentType }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="font-weight-bold text-20">
            $ {{ price.value | currencyPVP }}
          </span>
          <span class="green--text text--darken-2 ml-3">
            {{ price.discount }}% de descuento
          </span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: Object,
      required: true,
      default: () => {}
    },
    isAuth: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  filters: {
    currencyPVP(value) {
      if (value) {
        const AMOUNT_FORMAT = new Intl.NumberFormat("de-DE", {
          // currency: "ARS",
          // style: "currency",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        }).format(value);
        return AMOUNT_FORMAT;
      } else {
        return " ";
      }
    }
  },

  data() {
    return {};
  }
};
</script>

<style>
.text-20 {
  font-size: 1.5em;
}

.text-17 {
  font-size: 17px !important;
}
</style>
