<template>
  <v-container>
    <h1 class="text-center title-stadio">Stadio</h1>

    <v-row justify="center">
      <v-col cols="12" md="9" class="mt-5">
        <div class="description">
          Stadio significa escenario en griego. Y para nosotros un escenario es
          todo lugar donde podés desplegar tu talento.
        </div>

        <div class="information mt-5">
          Nuestro objetivo principal es facilitar la compra de tus equipos y acá
          te contaremos un poco de cómo nos fuimos transformando para mantener
          ese foco siempre.
        </div>
        <div class="information mt-5">
          No somos un e-commerce cualquiera, somos Importadores Oficiales de
          todas las marcas que están en nuestro catálogo y los creadores de
          PROCO, una marca que tiene más de 12 años en el mercado. Nacimos en el
          1999 como una empresa mayorista y desde ese primer día teníamos claro
          nuestra misión: buscar y crear los mejores productos posibles.
        </div>
        <div class="information mt-5">
          Nos fuimos transformando y cambiamos la visión, así en 2018 comenzamos
          a crear Stadio, donde todo lo pensamos colocándote en el centro de la
          escena, como protagonista y lo más importe. Por eso empezamos a
          construir un nuevo camino para hacer realidad un concepto sencillo,
          seguir buscando y desarrollando los mejores productos, pero ahora
          desde la fabrica a tus manos y de la manera más ágil posible.
        </div>
        <div class="information mt-5">
          Lo hacemos muy eficiente, sin generar gastos extras que después
          impacten en el precio del producto. Ese ahorro que generamos se
          traduce en productos mucho más accesibles para nuestros clientes.
        </div>
        <div class="information mt-5">
          Ahora estamos 100% online, no es nuestra prioridad tener
          local/showroom, aunque en un futuro podamos tenerlos. Pero hoy,
          sabemos que la necesidad más importante es que los productos sean
          accesibles, que puedas comprar calidad y que no pagues mas de los que
          necesitás. Stadio puede ser nuevo en este momento que estas leyendo,
          pero tenemos 23 años de experiencia y estamos en constante
          transformación y mejora.
        </div>
        <div class="information my-5">
          Quedate atento/a, que pronto te estaremos contando más novedades.
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
.title-stadio {
  font-weight: 800;
  color: #6e30e8;
}

.description {
  color: black;
  font-style: oblique;
  font-size: 1em;
}

.information {
  color: black;
  font-size: 1em;
  line-height: 25px;
}
</style>
