/* eslint-disable */
import Home from "../components/views/Home.vue";
import Products from "../components/views/Products.vue";
import ProductDetails from "../components/views/ProductDetails.vue";
import ProductFavorite from "../components/views/ProductsFavorites.vue";
import Cart from "../components/cart/Cart.vue";
import Login from "../components/auth/Login.vue";
import Recovery from "../components/auth/Recovery.vue";
import Signup from "../components/auth/Signup.vue";
import Profile from "../components/auth/Profile.vue";
import Checkout from "../components/views/Checkout.vue";
import CheckoutNotification from "../components/views/CheckoutNotification.vue";
import OrderDetails from "../components/auth/utils/UserDetailsOrder.vue";
import ContactAccount from "../components/views/ContactAccount";
import AppDowload from "../components/views/AppDowload";
import Welcome from "../components/views/welcome";
import WhoAreWe from "../components/views/WhoAreWe";

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import store from "../store/index";

const routes = [
  { path: "/", component: Welcome, name: "welcome", redirect: "/home" },
  { path: "/home", component: Home, name: "home" },
  {
    path: "/login",
    component: Login,
    name: "login",
    beforeEnter: (to, from, next) => {
      if (from.name == undefined) {
        next("/");
      } else {
        store.commit(
          "auth/PREV_PAGE",
          from.name == "recovery" ? null : from.name
        );
        next();
      }
    }
  },
  {
    path: "/recovery",
    component: Recovery,
    name: "recovery"
    // meta: {
    //     requiresAuth: true
    // },
  },
  {
    path: "/signup",
    component: Signup,
    name: "signup"
    // meta: {
    //     requiresAuth: true
    // },
  },
  {
    path: "/profile",
    component: Profile,
    name: "profile"
    // meta: {
    //     requiresAuth: true
    // },
  },
  {
    path: "/products",
    component: Products,
    name: "products"
    // meta: {
    //     requiresAuth: true
    // },
  },
  {
    path: "/product-details",
    component: ProductDetails,
    name: "product_details"
    // meta: {
    //     requiresAuth: true
    // },
  },
  {
    path: "/product-favorite",
    component: ProductFavorite,
    name: "product_favorite"
    // meta: {
    //     requiresAuth: true
    // },
  },
  {
    path: "/cart",
    component: Cart,
    name: "cart"
    // meta: {
    //     requiresAuth: true
    // },
  },
  {
    path: "/order_details",
    component: OrderDetails,
    name: "order_details"
    // meta: {
    //     requiresAuth: true
    // },
  },
  {
    path: "/checkout",
    component: Checkout,
    name: "checkout"
    // meta: {
    //     requiresAuth: true
    // },
  },
  {
    path: "/checkout_notification",
    component: CheckoutNotification,
    name: "checkout_notifiction"
    // meta: {
    //     requiresAuth: true
    // },
  },
  {
    path: "/contact",
    component: ContactAccount,
    name: "contact"
    // meta: {
    //     requiresAuth: true
    // },
  },
  {
    path: "/app_dowload",
    component: AppDowload,
    name: "app_dowload"
    // meta: {
    //     requiresAuth: true
    // },
  },

  {
    path: "/who_are",
    component: WhoAreWe,
    name: "WhoAreWE"
    // meta: {
    //     requiresAuth: true
    // },
  },

  {
    path: "*",
    name: "not-found",
    // redirect: '/'
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/views/Error.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from) {
    if (to.name == "products" && from.name == to.name) {
      return {};
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const authUser = store.getters["auth/AUTHENTICATED"];

  // Verifico Autenticacion
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authUser) {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
