<template>
  <v-container>
    <h2 class="text-center">Estamos trabajando para voz.</h2>
    <v-img
      class="mx-auto"
      width="600"
      src="@/assets/img/sections/working.svg"
    ></v-img>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>